import axios from 'axios'

export default {
    namespaced: true,
    state: {
        meetingList: null,
        error: null,
    },

    getters: {
        getMeetings(state) {
            return state.meetingList;
        },
        getError(state) {
            return state.error;
        }
    },

    actions: {
        async postZoomQuery({ dispatch }, data) {
            //console.log(url)
            const response = await axios.post(data.url, data)
                //console.log(response)
            if (response.data.success != 1) {
                dispatch('setErrror', response.data.message)
            } else {
                dispatch('passMeeting', response.data)
                dispatch('setErrror', '')
            }
        },
        async passMeeting(context, data) {
            try {
                //console.log(data)
                context.commit('SET_MEETINGS', data)
            } catch (e) {
                //console.log('failed')
            }
        },
        async setErrror(context, err) {
            context.commit('SET_ERROR', err)
        },
    },

    mutations: {
        SET_MEETINGS(state, list) {
            state.meetingList = list
        },
        SET_ERROR(state, data) {
            state.error = data
        }
    }

}