import axios from 'axios'

export default{ 
    namespaced: true,
    state: {
        questionList: null,
        error: null,
    },

    getters: {
        questions(state){
            return state.questionList;
        },
        getError(state){
            return state.error;
        }
    },

    actions: {
        
        async fetchQuestion({ dispatch }, url) {
            //console.log(url)
            const response = await axios.get(url)
            //console.log(response)
            if(response.data.success != 1){
                dispatch('setErrror', response.data.message)
            }
            else{
            dispatch('passQuestion', response.data)
            dispatch('setErrror', '')
            }
        },
        async passQuestion(context, data){
            try{
                //console.log(data)
                context.commit('SET_QUESTIONS', data)
            }
            catch(e){
                //console.log('failed')
            }
        },
        async setErrror(context,err){
            context.commit('SET_ERROR', err)
        },
        async postAnswer(url, params){
            let res = await axios.post(url, params);
            console.log(res);
        }
    },

    mutations: {
        SET_QUESTIONS(state,list){
            state.questionList = list
        },
        SET_ERROR(state,data){
            state.error = data
        }
    }

}