import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import speakmodule from './modules/speakmodule'
import writtingmodule from './modules/writtingmodule'
import listeningmodule from './modules/listeningmodule'
import readingmodule from './modules/readingmodule'
import mocktest from './modules/mocktestmodule'
import usermocktest from './modules/usermocktest'
import createquestion from './modules/createquestion'
import loginmodule from './modules/loginmodule'
import zoommeeting from './modules/zoommeeting'
import paypal from './modules/paypal'
import premiumvideo from './premiumvideo.js'
Vue.use(Vuex)

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        auth,
        speakmodule,
        writtingmodule,
        listeningmodule,
        readingmodule,
        mocktest,
        createquestion,
        loginmodule,
        usermocktest,
        zoommeeting,
        paypal,
        premiumvideo
    }
})