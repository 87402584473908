import axios from 'axios'

export default{ 
    namespaced: true,
    state: {
        questionList: null,
        error: null,
        MockQues:null,
        questionResponse:null,
        mock_title:'',
        mock_type:'',
        mock_test_id:0,
        mock_module:'speaking',
        
    },
    getters: {
        questions(state){
            return state.questionList;
        },
        getError(state){
            return state.error;
        },
        mockQuestions(state){
            return state.MockQues;
        },
        QuestionResponse(state){
            return state.questionResponse
        },
    },
    actions: {
        async fetchQuestion({ dispatch }, url) {
            //console.log(url)
            const response = await axios.get(url)
            //console.log(response)
            if(response == null | response == undefined) return;
            if(response.data.success != 1){
                dispatch('setErrror', response.data.message)
            }
            else{
            dispatch('passQuestion', response.data)
            dispatch('setErrror', '')
            }
        },
        async fetchMockQuestion({ dispatch }, url) {
            //console.log(url)
            const response = await axios.get(url)
            //console.log(response)
            if(response == null | response == undefined) return;
            if(response.data.success != 1){
                dispatch('setErrror', response.data.message)
                return 'error'
            }
            else{
            dispatch('mockQuestion', response.data)
            dispatch('setErrror', '')
            return 'success'
            }
        },
        async passQuestion(context, data){
            try{
                //console.log(data)
                context.commit('SET_QUESTIONS', data)
            }
            catch(e){
                //console.log('failed')
            }
        },
        async mockQuestion(context, data){
            try{
                //console.log(data)
                context.commit('SET_MOCKQUESTIONS', data)
            }
            catch(e){
                //console.log('failed')
            }
        },
        async setErrror(context,err){
            context.commit('SET_ERROR', err)
        },
        async postAnswer(url, params){
            let res = await axios.post(url, params);
            console.log(res);
        },
        async saveMockTest({ dispatch }, data) {
            
            //console.log(data.url)
            //console.log(data)
            const response = await axios.post(data.url,data)
            //console.log(response)
            if(response.data.success != 1){
                dispatch('setErrror', response.data.message)
            }
            else{
            dispatch('passQuestionResponse', response.data)
            dispatch('setErrror', '')
            }
        },
        async passQuestionResponse(context, data){
            try{
                //console.log(data)
                context.commit('SET_QUESTION_RESPONSE', data)
            }
            catch(e){
                //console.log('failed')
            }
        },
    },

    mutations: {
        SET_QUESTIONS(state,list){
            state.questionList = list
        },
        SET_QUESTION_RESPONSE(state,list){
            state.questionResponse = list
        },
        SET_ERROR(state,data){
            state.error = data
        },
        SET_MOCKQUESTIONS(state,list){
            state.MockQues = list
        },
    }

}