import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'

// import MockView from '../views/MockTestView.vue'

// import Login from '@/components/login.vue'

import ResetPassword from '@/components/ResetPassword.vue'

// import CancelPage from '@/components/notused/cancel_payment.vue'

// import PaymentSuccess from '@/components/notused/paymentSuccess.vue'

// import PaymentSuccessCoaching from '@/components/notused/coachingPaymentSuccess.vue'

import ResetPasswordRedirect from '@/components/notused/resetPasswordRedirect.vue'

// import PayPalPaymentSuccess from '@/components/notused/payPalPaymentSuccess.vue'

import EmailVerification from '@/components/notused/emailVerification.vue'


Vue.use(VueRouter)


const routes = [{
        path: '/login',
        name: 'Login',
        component: Home
    },
    {
        path:'/appleLoginDone',
        name:'AppleLogin',
        component:Home
    },
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '?moduleType',
        name: 'Practice',
        // props: { dynamicPropsFn },
        // props: { moduleType: '' },
        component: Home
    },
    // {
    //     path: '/cancel_payment',
    //     name: 'cancelPayment',
    //     component: CancelPage
    // },
    {
        path: '/?cancel_payment',
        name: 'cancelPayment',
        component: Home
    },
    // {
    //     path: '/successPayment:session_id',
    //     name: 'successPayment',
    //     component: PaymentSuccess
    // },
    {
        path: '/?successPayment',
        name: 'successPayment',
        component: Home
    },
    // {
    //     path: '/PaymentSuccessCoaching:session_id',
    //     name: 'PaymentSuccessCoaching',
    //     component: PaymentSuccessCoaching
    // },
    {
        path: '/?PaymentSuccessCoaching',
        name: 'PaymentSuccessCoaching',
        component: Home
    },
    // {
    //     path: '/PayPalPaymentSuccess:planType',
    //     name: 'PayPalPaymentSuccess',
    //     component: PayPalPaymentSuccess
    // },
    {
        path: '/?PayPalPaymentSuccess',
        name: 'PayPalPaymentSuccess',
        component: Home
    },
    {
        path: '/?state',
        name: 'state',
        component: Home
    },
    {
        path: '/ResetPasswordRedirect:token',
        name: 'ResetPasswordRedirect',
        component: ResetPasswordRedirect
    },
    {
        path: '/EmailVerification:token',
        name: 'EmailVerification',
        component: EmailVerification
    },
    {
        path: '/ResetPassword:token',
        name: 'ResetPassword',
        component: ResetPassword
    },
    {
        path: '/?viewType=mockTest',
        name: 'mockTest',
        component: Home
    },
    {
        path: '/?userSettings',
        name: 'userSettings',
        component: Home
    },
    // {
    //     path: '/mockTest',
    //     name: 'mockTest',
    //     component: MockView
    // },
    {
        path: '/?referral',
        //redirect: '/'
        name: 'referral',
        component: Home,
        // beforeEnter: (to, from, next) => {
        //     //alert(to.path)
        //     console.log(to.path)
        //         //debugger
        //         //Vue.$ls.set('referralId', to.params.referral)
        //     next({ path: '/' })
        //         //     if(to.path.includes('/:session_id')) {
        //         //       Vue.$ls.set('sessionId',to.params.session_id)
        //         //       next({ path: '/'});
        //         //     }
        //         // if(to.path.includes('/cancel_payment')) {
        //         //   //alert('Inside')
        //         //   Vue.$ls.set('cancel_Payment','cancel')
        //         //   next({ path :'/'});
        //         // }
        //         // else if(to.path.includes('cancel_payment')) {
        //         //   //alert(to.path)
        //         //   Vue.ls.set('cancel_Payment','cancel')
        //         //   next({ path: '/'});
        // }
    },
    {
        path: '*',
        component: Home
    }
    //},

    // {
    //   path: '/cancel_payment',
    //   name: 'StripPayment',
    //   component: Home,
    //   beforeEnter: (to, from, next) => {
    //     console.log(to.path)
    //     ///alert(to.path)
    //     // if(to.path.includes('/:session_id')) {
    //     //   Vue.ls.set('sessionId',to.params.session_id)
    //     //   next({ path: '/'});
    //     // }
    //     // else  
    //     if(to.path.includes('/cancel_payment')) {
    //       alert('Inside')
    //       Vue.ls.set('cancel_Payment','cancel')
    //       next({ path :'/'});
    //     }
    //   }
    // },
    // {
    //   path: '/paymentSuccess/:session_id',
    //   name: 'PaymentHome',
    //   component: PaymentSuccess
    // },
    // ,{
    //   path: '/Register',
    //   name: 'Register',
    //   component: Register
    // },
    // {
    //   path: '/ForgotPassword',
    //   name: 'ForgotPassword',
    //   component: ForgotPassword
    //},
]
const router = new VueRouter({
    mode: 'history',
    //base: process.env.VUE_APP_SITE_BASE_URL,
    routes,
    scrollBehavior() {
        console.log("before Created App");
        const userHeader=window.navigator.userAgent.concat().toLowerCase();
        const deviceArray=["mobile","phone","iphone","android","mdip"];
       for (let index = 0; index < deviceArray.length; index++) {
        if(userHeader.includes(deviceArray[index])) return  window.location.href="https://mobpractice.pteclasses.com/";
       }
        
        return { x: 0, y: 0 }
    }
})

export default router