import axios from 'axios'

export default {
    namespaced: true,
    state: {
        questionList: null,
        error: null,
        answerResponse: null,
        deleteResponse: null
    },

    getters: {
        questions(state) {
            return state.questionList;
        },
        getError(state) {
            return state.error;
        },
        AnswerResponse(state) {
            return state.answerResponse
        },
        DeleteResponse(state) {
            return state.deleteResponse
        }
    },

    actions: {
        async fetchQuestion({ dispatch }, url) {
            ////console.log(url)
            const response = await axios.get(url)
                ////console.log(response)
            if (response == undefined) {
                ////console.log('Issue on data reterival')
                return dispatch('setErrror', 'Issue on Data reterival')
            }
            if (response.data.success != 1) {
                dispatch('setErrror', response.data.message)
            } else {
                dispatch('passQuestion', response.data)
                dispatch('setErrror', '')
            }
        },
        async passQuestion(context, data) {
            try {
                ////console.log(data)
                context.commit('SET_QUESTIONS', data)
            } catch (e) {
                ////console.log('failed')
            }
        },
        async passAnswerResponse(context, data) {
            try {
                ////console.log(data)
                context.commit('SET_ANSWER_RESPONSE', data)
            } catch (e) {
                ////console.log('failed')
            }
        },
        async passDeleteResponse(context, data) {
            try {
                ////console.log(data)
                context.commit('SET_DELETE_RESPONSE', data)
            } catch (e) {
                ////console.log('failed')
            }
        },
        async saveAnswer({ dispatch }, data) {
            ////console.log(data)
            const response = await axios.post(data.url, data)
                ////console.log(response)
            if (response.data.success == 0) {
                dispatch('setErrror', response.data.message)
            } else {
                dispatch('passAnswerResponse', response.data)
                dispatch('setErrror', '')
            }
        },
        async getTranscripty({ dispatch }, data) {
            ////console.log(data)
            const response = await axios.post(data.url, data)
                ////console.log(response)
            if (response != null) {
                if (response.data.success != 1) {
                    dispatch('setErrror', response.data.message)
                } else {
                    dispatch('passAnswerResponse', response)
                    dispatch('setErrror', '')
                }
            }
        },
        async getDictionery({ dispatch }, url) {
            ////console.log(url)
            const response = await axios(url)
            debugger
                console.log(response)
            if (response == undefined) {
                ////console.log('Issue on data reterival')
                return dispatch('setErrror', 'Issue on Data reterival')
            }
            if (response.data[0].word.length <= 0) {
                dispatch('setErrror', response.data.message)
            } else {
                dispatch('passQuestion', response.data)
                dispatch('setErrror', '')
            }
        },
        async saveQuestion({ dispatch }, data) {

            ////console.log(data.url)
            ////console.log(data)
            const response = await axios.post(data.url, data)
                ////console.log(response)
            if (response.data.success != 1) {
                dispatch('setErrror', response.data.message)
            } else {
                dispatch('passAnswerResponse', response.data)
                dispatch('setErrror', '')
            }
        },
        async uploadFile({ dispatch }, data) {
            const formData = new FormData();
            console.log('Inside Upload Folder')
            console.log(data.files)
            for (const i of Object.keys(data.files)) {
                // Adding a new file name for logic in the back end 
                //path--name in fileName 
                var fileNameSplitArray = data.files[i].name.toLowerCase().split('.');
                const newFileName = data.fileName + '.' + fileNameSplitArray[fileNameSplitArray.length - 1]
                formData.append('files', data.files[i], newFileName)
            }
            const response = await axios.post('/multerFileUploadlocalDrive/file-upload', formData)
            console.log(response)
            if (response.data.success != 1) {
                dispatch('setErrror', response)
            } else {
                dispatch('passAnswerResponse', response.message)
                dispatch('setErrror', '')
            }
        },
        async generateFileUpload({ dispatch }, formData) {
            const response = await axios.post('/multerFileUploadlocalDrive/file-upload', formData)
            console.log(response)
            if (response == undefined) {
                dispatch('setErrror', response)
            } else if (response.data.success != 1) {
                dispatch('setErrror', response)
            } else {
                dispatch('passAnswerResponse', response.data.message)
                dispatch('setErrror', '')
            }
        },
        async deleteQuestion({ dispatch }, data) {

            ////console.log(data.url)
            //////console.log(data)
            const response = await axios.delete(data.url)
                ////console.log(response)
            if (response.data.success != 1) {
                dispatch('setErrror', response.data.message)
            } else {
                dispatch('passDeleteResponse', response.data)
                dispatch('setErrror', '')
            }
        },
        async setErrror(context, err) {
            context.commit('SET_ERROR', err)
        }
    },

    mutations: {
        SET_QUESTIONS(state, list) {
            state.questionList = list
        },
        SET_ANSWER_RESPONSE(state, list) {
            state.answerResponse = list
        },
        SET_DELETE_RESPONSE(state, res) {
            state.deleteResponse = res
        },
        SET_ERROR(state, data) {
            state.error = data
        }
    }

}