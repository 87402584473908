import axios from 'axios'

export default{ 
    namespaced: true,
    state: {
        videoList: null,
        error: null,
        countResponse:null
    },

    getters: {
        videos(state){
            return state.videoList;
        },
        saveCountResponse(state){
            return state.countResponse;
        },
        getError(state){
            return state.error;
        }
    },

    actions: {
        
        async fetchVideo({ dispatch }, url) {
            //console.log(url)
            const response = await axios.get(url)
            //console.log(response)
            if(response.data.success != 1){
                dispatch('setErrror', response.data.message)
            }
            else{
            dispatch('passVideo', response.data)
            dispatch('setErrror', '')
            }
        },
        async passVideo(context, data){
            try{
                //console.log(data)
                context.commit('SET_VIDEOS', data)
            }
            catch(e){
                //console.log('failed')
            }
            
        },
        async setErrror(context,err){
            context.commit('SET_ERROR', err)
        },
        async passResponse(context, data){
            try{
                //console.log(data)
                context.commit('SET_COUNTRESPONSE', data)
            }
            catch(e){
                //console.log('failed')
            }
        },
        async postLikeDislike({ dispatch}, data) {
            //console.log(data)
            const response = await axios.post(data.url,data)
            //console.log(response)
            if(response.data.success != 1){
                dispatch('setErrror', response.data.message)
            }
            else{
            dispatch('passResponse', response.data)
            dispatch('setErrror', '')
            }
        },
    },

    mutations: {
        SET_VIDEOS(state,list){
            state.videoList = list
        },
        SET_ERROR(state,data){
            state.error = data
        },
        SET_COUNTRESPONSE(state,data){
state.countResponse=date;
        }
    }

}