import axios from 'axios'

export default {
    namespaced: true,
    state: {
        ppResponse: null,
        error: null,
    },

    getters: {
        getPayPalResponse(state) {
            return state.ppResponse;
        },
        getError(state) {
            return state.error;
        }
    },

    actions: {
        async postPayPalQuery({ dispatch }, data) {
            console.log(data.url)
            const response = await axios.post(data.url, data)
            //console.log(response)
            if (response.data.status != 200) {
                console.log(response)
                dispatch('setErrror', response)
            } else {
                dispatch('passResponse', response.data)
                dispatch('setErrror', '')
            }
        },
        async passResponse(context, data) {
            try {
                //console.log(data)
                context.commit('SET_RESPONSE', data)
            } catch (e) {
                //console.log('failed')
            }
        },
        async setErrror(context, err) {
            context.commit('SET_ERROR', err)
        },
    },

    mutations: {
        SET_RESPONSE(state, list) {
            state.ppResponse = list
        },
        SET_ERROR(state, data) {
            state.error = data
        }
    }

}