// LocalStorageService.js
const LocalStorageService = (function(){
    var _service;
    function _getService() {
        if(!_service) {
          _service = this;
          return _service
      }
      return _service
    }
    function _setToken(tokenObj) {
        ////console.log(tokenObj)
      localStorage.setItem('access_token', tokenObj);
      localStorage.setItem('refresh_token', tokenObj);
    }
    function _setUser(userObj) {
      ////console.log(tokenObj)
    localStorage.setItem('userData', JSON.stringify(userObj));
    }
    function _setCurrentQuestion(question) {
      ////console.log(tokenObj)
    localStorage.setItem('currentQuestion', question);
    }
    function _getUser() {
      return JSON.parse(localStorage.getItem('userData'));
    }
    function _getCurrentQuestion() {
      return localStorage.getItem('currentQuestion');
    }
    function _setResetToken(userObj) {
      ////console.log(tokenObj)
    localStorage.setItem('restpass', JSON.stringify(userObj));
    }
    function _getResetToken() {
      return JSON.parse(localStorage.getItem('restpass'));
    }
    function _getAccessToken() {
      return localStorage.getItem('access_token');
    }
    function _getRefreshToken() {
      return localStorage.getItem('refresh_token');
    }
    function _clearToken() {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
    }
   return {
      getService : _getService,
      setToken : _setToken,
      getAccessToken : _getAccessToken,
      getRefreshToken : _getRefreshToken,
      clearToken : _clearToken,
      userData: _setUser,
      getUser:_getUser,
      getResetToken: _getResetToken,
      setResetToken: _setResetToken,
      setCurrentQuestion:_setCurrentQuestion,
      getCurrentQuestion:_getCurrentQuestion
    }
   })();
   export default LocalStorageService;