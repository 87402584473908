import axios from 'axios'

export default{ 
    namespaced: true,
    state: {
        questionList: null,
        error: null,
        answerResponse: null,
    },

    getters: {
        questions(state){
            return state.questionList;
        },
        getError(state){
            return state.error;
        },
        AnswerResponse(state){
            return state.answerResponse
        }
    },

    actions: {
        async fetchQuestion({ dispatch}, url) {
            //console.log(url)
            const response = await axios.get(url)
            //console.log(response)
            if(response.data.success != 1){
                dispatch('setErrror', response.data.message)
            }
            else{
            dispatch('passQuestion', response.data)
            dispatch('setErrror', '')
            }
        },
        async passQuestion(context, data){
            try{
                //console.log(data)
                context.commit('SET_QUESTIONS', data)
            }
            catch(e){
                //console.log('failed')
            }
        },
        async passAnswerResponse(context, data){
            try{
                //console.log(data)
                context.commit('SET_ANSWER_RESPONSE', data)
            }
            catch(e){
                //console.log('failed')
            }
        },
        async saveAnswer({ dispatch}, data) {
            //console.log(data)
            const response = await axios.post(data.url,data)
            console.log(response)
            if(response.data.success != 1 && response.data.success != 2){
                dispatch('setErrror', response.data.message)
            }
            else
            {
            dispatch('passAnswerResponse', response.data)
            dispatch('setErrror', '')
            }
        },
        async updatePassword({ dispatch}, data) {
            //console.log(data)
            const response = await axios.post(data.url,data)
            //console.log(response)
            if(response.data.success != 1){
                dispatch('setErrror', response.message)
            }
            else{
            dispatch('passAnswerResponse', response.message)
            dispatch('setErrror', '')
            }
        },
        async saveQuestion({ dispatch}, data) {
            
            //console.log(data.url)
            //console.log(data)
            const response = await axios.post(data.url,data)
            //console.log(response)
            if(response.data.success != 1){
                dispatch('setErrror', response.data.message)
            }
            else{
            dispatch('passAnswerResponse', response.data)
            dispatch('setErrror', '')
            }
        },
        async setErrror(context,err){
            context.commit('SET_ERROR', err)
        }
    },

    mutations: {
        SET_QUESTIONS(state,list){
            state.questionList = list
        },
        SET_ANSWER_RESPONSE(state,list){
            state.answerResponse = list
        },
        SET_ERROR(state,data){
            state.error = data
        }
    }

}