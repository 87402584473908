import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';
import {
    BootstrapVue,
    BIcon,
    BIconPlus,
    BIconDashCircle,
    BIconTrashFill,
    BIconVolumeUpFill,
    BIconVolumeDownFill,
    BIconMicFill,
    BIconSearch,
    BIconStopwatch,
    BIconJustifyRight,
    BIconShieldLockFill,
    BIconLock,
    BIconChatDotsFill,
    BIconHandThumbsUp,
    BIconFolder,
    BIconChatRightText,
    BIconPlayFill,
    BIconCameraVideoFill,
    BIconHandThumbsDown
} from 'bootstrap-vue'
import 'bootstrap'
import 'jquery-ui-dist/jquery-ui'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import '@/assets/css/admincss/css/style.css'
import '@/assets/css/admincss/css/responsive.css'
import '@/assets/css/admincss/css/font-awesome.css'
import '@/assets/admin/main.css'
import '@/assets/css/style.css'
import '@/assets/css/responsive.css'
import '@/assets/css/font-awesome.css'
import '@/assets/css/mobileView.css'
import LocalStorageService from '../src/store/LocalStorageService'
import VueSimpleAlert from "vue-simple-alert";
import ToggleButton from 'vue-js-toggle-button'
// import VueRecord from '@codekraft-studio/vue-record' IconsPlugin
import AudioRecorder from 'audio-recorder-vue' //'vue-audio-recorder'
import ReadMore from 'vue-read-more';
import Storage from 'vue-ls';
import VueSlideBar from 'vue-slide-bar'
import VueApexCharts from 'vue-apexcharts'
import { Datetime } from 'vue-datetime'
import VueHtml2Canvas from 'vue-html2canvas';
import Embed from 'v-video-embed'
import VueCryptojs from 'vue-cryptojs'
import browserDetect from "vue-browser-detect-plugin";
import VueAppleLogin from "vue-apple-login";
// You need a specific loader for CSS files
import 'vue-datetime/dist/vue-datetime.css'
//import VueGoogleMaps from '@fawmi/vue-google-maps'
//https://robinck.gitbooks.io/vue-storage/content/api.html
const options = {
    namespace: 'vuejs__pteclasses', // key prefix
    name: 'ls', // name variable Vue.[ls] or this.[$ls],
    storage: 'session', // storage name session, local, memory
};
import VueMeta from 'vue-meta'
import VeeValidate from 'vee-validate';
import VueBrowserstore from 'vue-browserstore'
import $ from 'jquery'

Vue.use(VeeValidate);
Vue.use(VueMeta)
Vue.use(Storage, options);
Vue.use(ReadMore);
Vue.use(AudioRecorder)
Vue.use(VueApexCharts)
Vue.component('datetime', Datetime);
Vue.use(VueHtml2Canvas)
Vue.use(Embed);
Vue.use(VueCryptojs);
Vue.use(browserDetect);

//Vue.component('apexchart', VueApexCharts)
//Vue.use(VueGoogleMaps)
//Vue.use(VueRecord)

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
 //Need to add this in env file "https://practice.pteclasses.com/developer/api" "http://202.59.94.220:3885/api";//
//axios.defaults.baseURL =  'https://practice.pteclasses.com/TestingDeleoperAPI/api'//'https://pteclasses.com/developer/api';

Vue.use(VueSimpleAlert);
Vue.use(BootstrapVue);
Vue.component('BIcon', BIcon);
Vue.component('BIconPlus', BIconPlus);
Vue.component('BIconDashCircle', BIconDashCircle);
Vue.component('BIconTrashFill', BIconTrashFill);
Vue.component('BIconVolumeUpFill', BIconVolumeUpFill);
Vue.component('BIconVolumeDownFill', BIconVolumeDownFill);
Vue.component('BIconMicFill', BIconMicFill);
Vue.component('BIconSearch', BIconSearch);
Vue.component('BIconStopwatch', BIconStopwatch)
Vue.component('BIconJustifyRight', BIconJustifyRight)
Vue.component('BIconShieldLockFill', BIconShieldLockFill)
Vue.component('BIconLock', BIconLock)
Vue.component('BIconChatDotsFill', BIconChatDotsFill)
Vue.component('BIconHandThumbsUp', BIconHandThumbsUp)
Vue.component('BIconHandThumbsDown', BIconHandThumbsDown)
Vue.component('BIconFolder', BIconFolder)
Vue.component('BIconChatRightText', BIconChatRightText)
Vue.component('BIconPlayFill', BIconPlayFill)
Vue.component('BIconCameraVideoFill', BIconCameraVideoFill)
Vue.component('VueSlideBar', VueSlideBar)
Vue.use(VueBrowserstore)
Vue.use(ToggleButton)
export const bus = new Vue();
Vue.config.productionTip = false
const localStorageService = LocalStorageService.getService();
Vue.use(localStorageService)



axios.interceptors.request.use(req => {
    
   
    //For File uploading
    if (req.url.includes('multerFileUploadlocalDrive')) return req
    if (req.url.includes('dictionaryapi')) return req
        // For Strip working
        // if(req.url.includes("checkout")){
        //   //axios.defaults.baseURL ="https://localhost:8080"
        //   //console.log(req)
        //   return req;
        // }
        //console.log(req)
        // debugger
        // if(req.body!=null){
        //   console.log(req.body)
        // if(req.data.user_id==null)
        // {
        //   req.body.user_id = Vue.$ls.get('user').user_id
        //   console.log(req.body.user_id)
        // }
        //}
    if (req.url != 'users/insertVerifyKey' & req.url != 'users/getUserByEmailID' & req.url !== 'users/login' & req.url !== 'users/createuser' & req.url !== 'users/getUserNameById/id' & req.url !== 'users/forgotPassword' & req.url !== 'mocktest/freeMock') {
        
        const token = Vue.ls.get('tokenVal')
        const uniqueKey=Vue.ls.get('uniqueKey');
        const headers={
            'Authorization':'Bearer ' + token,
            'UniqueKey':uniqueKey
        }
        req.headers=headers;
        //req.headers.authorization = "Bearer " + token;
    } else if (req.url == 'users/resetPassword') {
        const token = Vue.ls.get('tokenVal') 
        const uniqueKey=Vue.ls.get('uniqueKey');
        const headers={
            'Authorization':'Bearer ' + token,
            'UniqueKey':uniqueKey
        }
        req.headers=headers;
        //req.headers.authorization = "Bearer " + token;

    }
    return req;
});


axios.interceptors.response.use(function(res) {
    //return res
    //console.log('Error')
    //console.log(res)
//debugger

    if (res == undefined | res == null) {
        //Vue.ls.clear()
        //router.push('/')
        console.log(res)
            //location.reload();
        return;
    } else if (res.data.success != undefined) {
        //console.log(res)
        if(res.data.success==-1){
           // alert(res.data.error);
            Vue.alert(res.data.error,"Authorisation Issue","error",
            {
              confirmButtonText: "Sign Out"
            }).then(()=>{
                bus.$emit('DirectSignOut');
            });
        }
        else if(res.data.success==0 & res.data.message!="Invalid Credentials"){
            
            Vue.alert("Your current login session is expired, please login again.","Session Expired","error",
            {
              confirmButtonText: "Sign Out"
            }).then(()=>{
                bus.$emit('DirectSignOut');
            });
        }
        return res
    } else {
        console.log(res)
        return res
            //Vue.ls.clear()
            //router.push('/')
            // LocalStorageService.setToken(null);
            // LocalStorageService.userData(null);
            //location.reload();
    }

}, function(error) {
    debugger
    if (error.res == undefined) {
        console.log(error)
        if(error.message=="Network Error" & error.config.data==null){
            Vue.alert("You are facing network issue, try to refresh the page, if issue remains check your internet and login again.",error.message,"error");
            //alert("You are facing network issue, try to refresh the page, if issue remains check your internet and login again.")
        }
        //alert(error.message);
            //Vue.ls.clear()
            //router.push('/')
            //location.reload();
    } else if (error.res) {
        Vue.alert("You are facing network issue, try to refresh the page, if issue remains check your internet and login again.","Network Issue","error");
        console.log(error.res)
            //Vue.ls.clear()
            //router.push('/')
            //location.reload();
    } else if (401 === error.res.status | 404 === error.res.status) {
        Vue.alert("You are facing network issue, try to refresh the page, if issue remains check your internet and login again.","Network Issue","error");
        return error
    } else {
        Vue.alert("You are facing network issue, try to refresh the page, if issue remains check your internet and login again.","Network Issue","error");
        return error
    }
});

//const app = createApp(App);
//app.use(VueApexCharts);
//Vue.config.productionTip = false
// app.use(router, store,VueApexCharts, VueGoogleMaps, {
//   load: {
//     key: 'YOUR_API_KEY_COMES_HERE'
//   }
// }).mount('#app')
// const app = createApp(App);
// app.use(VueGoogleMaps, {
//     load: {
//         key: 'YOUR_API_KEY_COMES_HERE',
//     },
// }).$mount('#app')

// App.use(VueAppleLogin,{
//     clientId: 'thinkhigher.pteclasses.hybrid.app',
//     redirectURI: 'https://dummy.pteclasses.com/appleLoginDone',
//     scopes: 'email name',
//     state: '12345',
//     nonce: 'nonce',
//     usePopup: true,
// })
Vue.use(VueAppleLogin, {
    clientId: 'thinkhigher.pteclasses.hybrid.app.serviceId',
    redirectURI: 'https://practice.pteclasses.com/appleLoginDone',
    scopes: 'email name',
    state: '12345',
    usePopup: true,
})
new Vue({
    router,
    store,
    render: h => h(App),
    //components: { App }
}).$mount('#app')